import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import * as style from "./index.module.scss"

const AboutPage = () => (
  <Layout>
    <Seo title="About" />
    <>
      <div className={style.content}>
        <h1>About</h1>
        <p>
          My web development career started in the 1990s with plain HTML, jpegs,
          and an animated gif. Oh, how the web has changed since then. I have
          done my best to keep up with many of the changes, but if any web
          developer is honest, they will admit it is impossible to be
          knowledgeable in every aspect.
        </p>
        <p>
          I have worked with HTML, CSS, Javascript, PHP, MySQL, and content and
          learning management systems Drupal, QnECMS, WordPress, and Moodle. For
          a while, assistive technology did not work well with JavaScript, so I
          used JavaScript very sparingly and only where I could verify, as best
          I could, that the page was accessible to people with disabilities.
        </p>
        <p>
          Today, I'm working with Gatsby and React after both a Drupal group and
          work have discussed and decided to go the route of headless database
          websites. So I will be using my website to practice with Gatsby and
          React and make sure my skills are kept as relevant as possible while
          still being used primarily for online learning at my full-time job.
        </p>
      </div>
    </>
  </Layout>
)

export default AboutPage
